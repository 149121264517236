export default {
  masterChef: {
    7778: '0x607712710d398f77e924E35C8FbCBeE251c6c54f',
  },
  swapRewardsChef: {
    7778: '0xE5782a9823Fdcd3e2e147B54Be292F3C7522C818',
  },
  liquidStaking: {
    7778: '0xa09909a3F3413675a0cF096C474100b1E4d457De',
  },
  cakeVault: {
    7778: '0x0BF4f178467E8D89DE4A2276d0FDFE853Ee54122',
  },
  materialChef: {
    7778: '0x217da50b13682e74b519b3bb94b586d964e5f3ba',
  },
  phantzPool: {
    7778: '0xbe080A1Fee90c12fC7F308590DC56929E407aA6E',
  },
  phantzV2Pool: {
    7778: '0x6be4661405096e1dEEC1eD03250bB9d38aeA0804',
  },
  sousChef: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  lotteryV2: {
    7778: '0x9999999999999999999999999999999999999999', // avoid ENS errors
  },
  multiCall: {
    // Multicall2
    1: '0x0555fcE4231A25AcB648284bfecf6B85e1AF6Bd4',
    7778: '0x0555fcE4231A25AcB648284bfecf6B85e1AF6Bd4',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    128: '0x0555fcE4231A25AcB648284bfecf6B85e1AF6Bd4',
  },
  pancakeProfile: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  pancakeRabbits: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  bunnyFactory: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  claimRefund: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  pointCenterIfo: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  bunnySpecial: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  tradingCompetition: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  easterNft: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  predictions: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  chainlinkOracle: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  bunnySpecialCakeVault: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  bunnySpecialPrediction: {
    7778: '0x9999999999999999999999999999999999999999',
  },
  farmAuction: {
    7778: '0x9999999999999999999999999999999999999999',
  },
}
