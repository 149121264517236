import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'COAL',
    lpAddresses: {
      7778: '0x674B1C756781998bC917E38a976997FAec958a90',
    },
    token: tokens.excavator,
    quoteToken: tokens.wore,
  },
  {
    pid: 1,
    lpSymbol: 'COAL-ORE LP',
    lpAddresses: {
      7778: '0x674B1C756781998bC917E38a976997FAec958a90',
    },
    token: tokens.coal,
    quoteToken: tokens.wore,
  },
  {
    pid: 4,
    lpSymbol: 'COAL-USDT LP',
    lpAddresses: {
      7778: '0xAe6382e2DA998Bd6570C0C619E5dB0758ec3b3e5',
    },
    token: tokens.coal,
    quoteToken: tokens.usdt,
  },
  // {
  //   pid: 13,
  //   lpSymbol: 'stELA-ORE LP',
  //   lpAddresses: {
  //     7778: '0xE1349b31D91C1E00175343df9E60a1981086787A',
  //   },
  //   token: tokens.stela,
  //   quoteToken: tokens.wore,
  // },
  {
    pid: 2,
    lpSymbol: 'USDT-ORE LP',
    lpAddresses: {
      7778: '0x4301d8b210E804Ce8866B2e7BC5d74D87B7EdD3e',
    },
    token: tokens.usdt,
    quoteToken: tokens.wore,
  },
  {
    pid: 3,
    lpSymbol: 'ORE-GOLD LP',
    lpAddresses: {
      7778: '0xb4E8811F505cA0B98a1dDf797689439A5CE23933',
    },
    token: tokens.gold,
    quoteToken: tokens.wore,
  },
  // {
  //   pid: 4,
  //   lpSymbol: 'HUSD-USDT LP',
  //   lpAddresses: {
  //     7778: '0xB0917F2595A2c4C56498f6da2C52690a3EF558D2',
  //   },
  //   token: tokens.husd,
  //   quoteToken: tokens.usdc,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'HT-ORE LP',
  //   lpAddresses: {
  //     7778: '0xC6734784EE598855200dABC8D8B1fA1F11f14C90',
  //   },
  //   token: tokens.ht,
  //   quoteToken: tokens.wore,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'htFILDA-ORE LP',
  //   lpAddresses: {
  //     7778: '0x5B0Cf7D3b2D6885e1173674f4649B914e7A66B96',
  //   },
  //   token: tokens.htfilda,
  //   quoteToken: tokens.wore,
  // },
  // {
  //   pid: 8,
  //   lpSymbol: 'MTRL-ORE LP',
  //   lpAddresses: {
  //     7778: '0x825872e7AB3EE8ABf1f1239711375e4F2b587220',
  //   },
  //   token: tokens.mtrl,
  //   quoteToken: tokens.wore,
  // },
  // {
  //   pid: 9,
  //   lpSymbol: 'oELK-COAL LP',
  //   lpAddresses: {
  //     7778: '0x707A778354639EaAE03E739d2D5E8Db96CA30bF7',
  //   },
  //   token: tokens.oelk,
  //   quoteToken: tokens.coal,
  // },
  // {
  //   pid: 10,
  //   lpSymbol: 'BUSD-USDT LP',
  //   lpAddresses: {
  //     7778: '0x9ad2439CaE9440427f3dFF53A11A57A5a7211152',
  //   },
  //   token: tokens.busd,
  //   quoteToken: tokens.usdc,
  // },
  // {
  //   pid: 11,
  //   lpSymbol: 'BNB-ORE LP',
  //   lpAddresses: {
  //     7778: '0x421C1960169a50a963Cd8317d10b96298CaD0dc6',
  //   },
  //   token: tokens.bnb,
  //   quoteToken: tokens.wore,
  // },
  // {
  //   pid: 12,
  //   lpSymbol: 'ELK-COAL LP',
  //   lpAddresses: {
  //     7778: '0x59aDb497691A71831cDC07258ccCAa5294E68996',
  //   },
  //   token: tokens.elk,
  //   quoteToken: tokens.coal,
  // }
]

export default farms
